import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ArticleCard from "../components/article/ArticleCard"
import styles from "./article-list.module.scss"
import SEO from "../components/seo"

export default class ArticleList extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    return (
      <Layout>
        <SEO title="ワカールための記事一覧" />
        <h1 className={styles.title}>ワカールための記事一覧</h1>

        {posts.map(({ node }) => {
          return <ArticleCard node={node}></ArticleCard>
        })}

        {/* <div>
          {this.props.pageContext.previousPagePath ? <Link to={this.props.pageContext.previousPagePath}>Previous</Link> : ''}
          {this.props.pageContext.nextPagePath ? <Link to={this.props.pageContext.nextPagePath}>Next</Link> : ''}
        </div> */}
      </Layout>
    )
  }
}

export const articleListQuery = graphql`
  query articleListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/\/articles\//"}}
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 100)
          frontmatter {
            title
            date
          }
        }
      }
    }
  }
`
