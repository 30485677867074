import React from 'react'
import styles from "./index.module.scss"
import { Link } from "gatsby"

const ArticleCard = ({ node }) => (
  <div className={styles.card}>
    <div>{node.frontmatter.date}</div>
    <div className={styles.card__title}>
      <Link to={node.fields.slug} className={styles.card__link}>
        { node.frontmatter.title }
      </Link>
    </div>
  </div>
)
export default ArticleCard
